<template>
    <div>
        <h1>Timecards</h1>
        <div class="btn-group margin_bottom" role="group" aria-label="...">
            <button type="button" @click="payrollReport" class="btn btn-default">Payroll Report</button>
        </div>
        <form @submit.prevent="runReport">
            <Options @setDefaultDate="setDefaultDate" />
            <Results />
        </form>

        <Modal v-if="showHolidayHoursModal" :heading="'Add Holiday Hours'" @close="closeHolidayHours">
            <template v-slot:body>
                <form @submit.prevent="addHolidayHours">
                    <div class="form-group">
                        <label>Date</label>
                        <DatePicker v-model="holidayHoursDate" :input-attributes="{class: 'form-control'}"></DatePicker>
                    </div>

                    <button class="btn btn-primary">Submit</button>
                </form>
            </template>
        </Modal>
    </div>
</template>

<script>
    import Options from "@/components/reports/timecard_rpt_opt";
    import Results from "@/components/reports/utils/Results";
    import { store } from "@/store/Report.store";
    import Modal from "@/components/utils/Modal";
    import DatePicker from "@/components/utils/DatePicker";
    import DateHelpers from "@/store/DateHelpers";

    export default {
        name: "timecard_rpt",
        props: ['action'],
        components: {
            DatePicker,
            Modal,
            Options,
            Results,
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    dateTo: {
                        val: '',
                        qsField: '[date_to]'
                    },
                    cdiLocationID: {
                        val: 0,
                        qsField: '[numbers][s.cdi_location_id]'
                    },
                    shift: {
                        val: 0,
                        qsField: '[numbers][s.user_staff_shift_id]'
                    },
                    position: {
                        val: 0,
                        qsField: '[title]'
                    },
                    production: {
                        val: -1,
                        qsField: '[znumbers][production]'
                    },
                    shiftType: {
                        val: 0,
                        qsField: '[fstrings][user_staff_shift]'
                    },
                    sort: {
                        val: 'lname',
                        qsField: '[name_sort]'
                    },
                    exclude_temp: {
                        val: 1,
                        qsField: '[exclude_temp]'
                    }
                },
                groupByFields: {},
                dataFields: {},
                showHolidayHoursModal: false,
                holidayHoursDate: DateHelpers.getToday()
            }
        },
        methods: {
            runReport: function () {
                store.runReport();
            },
            setDefaultDate: function (date) {
                this.state.optionFields.dateTo.val = date;
                this.runReport();
            },
            payrollReport: function () {
                store.windowSubmit('to_payroll');
            },
            showHolidayHours: function () {
                this.showHolidayHoursModal = true;
            },
            addHolidayHours: function () {
                let options = {
                    'params[date]': this.holidayHoursDate
                };

                store.customAPI('timecard/add_holiday_hours', options);
                this.closeHolidayHours();
            },
            closeHolidayHours: function () {
                this.showHolidayHoursModal = false;
            }
        },
        created() {
            this.$appStore.validateFileAccess();
            this.$appStore.setTitle(['Timecards']);
            this.$set(store.state.results, this.$options.name, '');
            // We are stopping load on default until we have the value injected we need
            store.state.runOnLoad = false;
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
            store.state.extraTabs = [
                {
                    'action': 'showHolidayHours',
                    'label': 'Add Holiday Hours'
                }
            ];
        },
        watch: {
            action: function () {
                if (typeof this[this.action] === 'function') {
                    this[this.action]();
                }

                store.resetTabAction();
            }
        }
    }
</script>

<style scoped>

</style>