<template>
    <div class="panel panel-default">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Week Ending</label>
                <select class='form-control' v-model="state.optionFields.dateTo.val">
                    <option v-for="(date, id) in this.weekArray" v-bind:key="id" :value="date">{{ date }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select class='form-control' v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">{{ location }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shifts</label>
                <select class='form-control' v-model="state.optionFields.shift.val">
                    <option value="0">All</option>
                    <option v-for="[index, shift] in cache.shiftCache.SHIFTS" v-bind:key="index" :value="index">{{ shift }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Position</label>
                <select class='form-control' v-model="state.optionFields.position.val">
                    <option value="0">All</option>
                    <option v-for="(value, index) in cache.titleCache.TITLES" v-bind:key="index" :value="value">{{ value }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Area</label>
                <select class='form-control' v-model="state.optionFields.production.val">
                    <option value="-1">All</option>
                    <option value="0">Office</option>
                    <option value="1">Production</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Shift Type</label>
                <select class='form-control' v-model="state.optionFields.shiftType.val">
                    <option value="0">All</option>
                    <option v-for="[index, shift] in cache.shiftCache.SHIFT_TYPES" v-bind:key="index" :value="index">{{ shift }}</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Sort</label>
                <select class='form-control' v-model="state.optionFields.sort.val">
                    <option value="lname">Last Name</option>
                    <option value="fname">First Name</option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group form_grid">
                <label><input type=checkbox v-model="state.optionFields.exclude_temp.val" :true-value="1" :false-value="0" checked> Exclude Temp</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js'
    import shiftCache from '@/cache/shift.cache.js';
    import titleCache from '@/cache/title.cache.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';

    export default {
        name: "Options",
        data() {
            return {
                state: store.state,
                cache: {
                    shiftCache,
                    cdiLocationCache,
                    titleCache
                },
                weekArray: []
            }
        },
        created() {
            store.customAPI('/timecard/get_report_weeks_list').then(result => {
                this.weekArray = result;
                this.$emit('setDefaultDate', this.weekArray[0]);
            });
        }
    }
</script>

<style scoped>

</style>